import {
  Component,
  OnDestroy,
  OnInit,
  // ViewEncapsulation
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MainMenuModel } from '../../../../shared/models/navigation.model';

import { MainMenuEnum } from '../../../../shared/enums/navigation.enum';
import { getMainMenu } from '../../../../shared/utils/navigation.utils';
import { TokenStorageService } from '../../../../auth/shared/services/token-storage.service';

@Component({
  selector: 'app-common-container',
  templateUrl: './common-container.component.html',
  styleUrls: ['./common-container.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class CommonContainerComponent implements OnInit, OnDestroy {

  userProfile: any;

  public showUserDropdown$: Observable<boolean>;
  public showSideMenu = false;
  public employeeFullName: string;
  public notifications: any[] = [];
  public companyList$: Observable<any[]>;
  public employeeList$: Observable<any[]>;

  public title: string;
  public subTitle: string;
  public menuId: MainMenuEnum;
  public showSidebar = true;
  public mainMenu: MainMenuModel[];
  public sidebar = true;
  public showAppLoader: boolean;

  public avatarChange$: Subscription;

  constructor(private tokenStorageService: TokenStorageService) {}

  public ngOnInit(): void {
    this.userProfile = this.tokenStorageService.getUser();
    this.mainMenu = getMainMenu(this.userProfile);
    this.avatarChange$ = this.tokenStorageService.avatarChange.subscribe(res => {
      this.userProfile = this.tokenStorageService.getUser();
    });
  }

  ngOnDestroy(): void {
    this.avatarChange$.unsubscribe();
  }

  public updateMainMenu(): void {
    /**
     * @route /
     * @dispatch [Layout] Init main menu
     */
    // this.store.dispatch(LayoutActions.initMainMenuAction({ id: this.menuId }));

    // const sessionCompanyId = +localStorage.getItem(SessionStorageEnum.SelectedCompany);

    // if (this.showCompanySearch && !sessionCompanyId !== !this.selectedCompanyId) {
    //   this.onCompanySelect(sessionCompanyId);
    // }

    // if (!this.showCompanySearch && this.selectedCompanyId) {
    //   this.onCompanySelect(null);
    // }

    // const sessionEmployeeId = +localStorage.getItem(SessionStorageEnum.SelectedEmployee);

    // if (this.showEmployeeSearch && !sessionEmployeeId !== !this.selectedEmployeeId) {
    //   this.onEmployeeSelect(sessionEmployeeId);
    // }

    // if (!this.showEmployeeSearch && this.selectedEmployeeId) {
    //   this.onEmployeeSelect(null);
    // }
  }

  public openSubmenu(menuId: MainMenuEnum): void {
    /**
     * @route /
     * @dispatch [Layout] Open submenu
     */
    // this.store.dispatch(LayoutActions.openSubmenuAction({ id: menuId }));
  }

  public toggleUserDropdown(): void {
    /**
     * @route /
     * @dispatch [Layout] Toggle user dropdown
     */
    // this.store.dispatch(LayoutActions.toggleUserDropdownAction());
  }

  public logout(): void {
    this.tokenStorageService.logout();
  }

  public toggleSideMenu(): void {
    this.showSideMenu = !this.showSideMenu;
    // this.store.dispatch(LayoutActions.toggleSideMenuAction());
  }

  public toggleSidebar(): void {
    /**
     * @route /
     * @dispatch [Layout] Toggle sidebar
     */
    // this.store.dispatch(LayoutActions.toggleSidebarAction());
  }
}
