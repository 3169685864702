import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LoaderModule } from '../../shared/modules/loader';
import { CommonContainerComponent } from './components/common-container/common-container.component';
import { CommonHeaderComponent } from './components/common-header/common-header.component';
import { CommonRightSidebarComponent } from './components/common-right-sidebar/common-right-sidebar.component';
import { CommonSidebarComponent } from './components/common-sidebar/common-sidebar.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LoaderModule,
    RouterModule,
    BsDropdownModule.forRoot()
  ],
  declarations: [
    CommonContainerComponent,
    CommonHeaderComponent,
    CommonRightSidebarComponent,
    CommonSidebarComponent,
  ]
})
export class CommonLayoutModule {}
