<div class="common-header">
  <div class="common-header__logo">
    <img class="common-header__logo-image" src="assets/images/design/jobatory-logo.png" alt=""
      routerLink="/dashboard" />
  </div>
  <ng-container *ngFor="let menuItem of mainMenu">
    <div class="btn-group" dropdown *ngIf="menuItem.show">
      <button dropdownToggle type="button" class="btn dropdown-toggle btn-header-menu"
        [class.btn-header-menu--active]="parentRoute == menuItem.route">
        <i class="display" *ngIf="parentRoute == menuItem.route">
          <img src="/assets/images/icons/{{ menuItem.icon }}-active.svg" />
        </i>
        <i *ngIf="!(parentRoute == menuItem.route)">
          <img class="icon-header-menu" src="/assets/images/icons/{{ menuItem.icon }}.svg" />
          <img class="display-on-hover" src="/assets/images/icons/{{ menuItem.icon }}-active.svg" />
        </i>
        {{ menuItem.name }} <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <ng-container *ngFor="let subMenuItem of menuItem.subMenu">
          <li role="menuitem" *ngIf="subMenuItem.show">
            <a class="dropdown-item" routerLink="{{ subMenuItem.route }}" (click)="parentRoute = menuItem.route">
              <i>
                <img src="/assets/images/icons/submenuicom.svg" />
              </i>
              {{ subMenuItem.name }}
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
  <div class="common-header__profile">
    <ng-container>
      <div class="user-profile">
        <span dropdown>
          <a href id="basic-link" dropdownToggle (click)="(false)" aria-controls="basic-link-dropdown">
            <div class="user-profile__avatar">
              <img [src]="
                userProfile?.image_url || 'assets/images/no-avatar.jpg'
                " alt="{{ userProfile?.first_name }}" />
            </div>
          </a>

          <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu user-profile-dropdown" role="menu"
            aria-labelledby="basic-link">
            <div class="user-profile__account">
              <div class="user-profile__image-wrapper">
                <img class="user-profile__image" [src]="
                    userProfile?.image_url || 'assets/images/no-avatar.jpg'
                  " alt="{{ userProfile?.first_name }}" />
              </div>
              <div class="user-profile__info-wrapper">
                <div class="text-white text-fs-14">Welcome</div>
                <h4 class="text-white text-fs-16">
                  {{ userProfile?.first_name }} {{ userProfile?.last_name }}
                </h4>
                <a class="user-profile__my-account text-white text-fs-12"
                  [routerLink]="'/employees/update/' + userProfile?.id">My Account</a>
              </div>
            </div>
            <div class="user-profile__logout-wrapper">
              <a class="user-profile__logout text-fs-14" (click)="onLogoutClick()">Logout</a>
            </div>
          </ul>
          
        </span>
      </div>
      <div class="notification d-none d-sm-none d-md-none d-lg-block"></div>
    </ng-container>
    <div class="nav-toggler" [class.nav-toggler--opened]="sideMenuOpened" (click)="onShowSideMenu()"></div>
  </div>
</div>
