import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { MainMenuModel } from '../../../../shared/models/navigation.model';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss'],
})
export class CommonHeaderComponent implements OnInit {
  @Input() public userProfile: any;
  @Input() public mainMenu: MainMenuModel[];
  @Input() public notifications: any[] = [];

  @Output() public logoutClicked: EventEmitter<any> = new EventEmitter();
  @Output() public showSideMenu: EventEmitter<any> = new EventEmitter();

  public sideMenuOpened = true;
  public parentRoute: string;

  constructor(private router: Router) {}

  public ngOnInit(): void {
    this.parentRoute = this.getParentRoute();
  }

  public onLogoutClick(): void {
    this.logoutClicked.emit();
  }

  public onShowSideMenu(): void {
    this.sideMenuOpened = !this.sideMenuOpened;
    this.showSideMenu.emit();
  }

  private getParentRoute(): string {
    let route = '';
    const foundedRoute = this.mainMenu.find(x => x.subMenu.find(y => y.route === this.router.url));
    if (foundedRoute) {
      route = foundedRoute.route;
    }

    return route;
  }
}
